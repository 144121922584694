import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import successIcon from "../../../assets/img/success.svg";

const InterviewSuccess = () => {
  const location = useLocation();
  const isInterviewExpired = location.pathname.includes("meeting-expired");
  
  useEffect(() => {
    localStorage.setItem('userName', '');
  }, []);

  const handleCompleted = () => {
    window.location.href = 'https://www.google.com';
  };
  

  return (
    <div className="d-flex justify-content-center align-items-center success-container">
      <div className="text-center w-80 w-md-75 w-lg-50 px-3">
        <div
          className="success mx-auto"
          style={{
            backgroundColor: isInterviewExpired && "gray",
          }}
        >
          {" "}
          <img src={successIcon} alt="Success Icon" />
        </div>
        <h2 className="all-done mt-4">
          {isInterviewExpired ? "Meeting has expired." : "All Done"}
        </h2>
        {!isInterviewExpired && (
          <div>
              <p className="interview-text-primary mt-2">
              The AI is now evaluating your interview score. Please click ‘Completed’ to close the screen. 
                <br />
                Our team will be in touch with the next steps.
            </p>
            <br />
              <button
                className="btn btn-lg btn-primary-interview " 
                onClick={handleCompleted}
              >
                Completed
              </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InterviewSuccess;
