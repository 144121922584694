import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ConfirmDialog({
  open,
  setOpen,
  dialogTitle,
  handleConfirmYes,
  disableAction,
}) {
  const handleClose = () => {
    setOpen(false);

  };

  return (
    <Modal
      size="md"
      show={open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {dialogTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={disableAction}
            className="mx-2"
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmYes}
            disabled={disableAction}
            className="mx-2"
          >
            Yes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmDialog;
