import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivatePortal from "./pages/private";
import PublicPortal from "./pages/public";
import Home from "./pages/public/Interview/InterviewOverview";
import InterviewSetup from "./pages/public/Interview/InterviewSetup";
import InterviewStart from "./pages/public/Interview/InterviewStart";
import InterviewLayout from "./components/Layouts/InterviewLayout";
import InterviewSuccess from "./pages/public/Interview/InterviewSuccess";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <AuthGuardRedirect redirectTo="/user/meetings">
              <PublicPortal />
            </AuthGuardRedirect>
          }
        />
        <Route
          path="user/*"
          element={
            <RequireAuth redirectTo="/">
              <PrivatePortal />
            </RequireAuth>
          }
        />

        <Route path="meeting/*" element={<>{<InterviewLayout />}</>}>
          <Route
            path=":token/overview"
            element={
              <React.Suspense fallback={<></>}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path=":token/setup"
            element={
              <React.Suspense fallback={<></>}>
                <InterviewSetup />
              </React.Suspense>
            }
          />
          <Route
            path=":token/start"
            element={
              <React.Suspense fallback={<></>}>
                <InterviewStart />
              </React.Suspense>
            }
          />
          <Route
            path=":token/all-done"
            element={
              <React.Suspense fallback={<></>}>
                <InterviewSuccess />
              </React.Suspense>
            }
          />
         <Route
            path="meeting-expired"
            element={
              <React.Suspense fallback={<></>}>
                <InterviewSuccess />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
      <ToastContainer hideProgressBar />
    </>
  );
}

export default App;

function RequireAuth({ children, redirectTo }) {
  const isAuthenticated = localStorage.getItem("token");
  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }
  return isAuthenticated ? children : null;
}
function AuthGuardRedirect({ children, redirectTo }) {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}
