import React, { useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "../../../assets/img/Icon.svg";
import wave from "../../../assets/img/sound-wave-icon.svg";
import Loader from "../Loader";

const InterviewSetup = () => {
  const [showFirst, setShowFirst] = useState(true);
  const [showSecond, setShowSecond] = useState(false);
  const [showThired, setShowThired] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const [audioLoaded, setAudioLoaded] = useState(false);
  const [currentQuestions, setCurrentQuestions] = useState("");

  const webSocket = useRef(null);
  const audioRef = useRef(null);
  const id = localStorage.getItem("id");
  const wsUrl = `${process.env.REACT_APP_WEB_SOCKET}/${id}`;
  const connectWebSocket = () => {
    webSocket.current = new WebSocket(wsUrl);

    webSocket.current.onopen = () => {
      console.log("WebSocket Connected");
      webSocket.current.send("test_question");
    };

    webSocket.current.onmessage = (event) => {
      if (typeof event.data === "string") {
        console.log("Interview Started:", event.data);
        setCurrentQuestions(event.data);
      } else {
        console.log("Audio message received:", event.data);
        const audioBlob = new Blob([event.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(audioBlob);
        if (audioRef.current) {
          audioRef.current.src = audioUrl;
          audioRef.current.onloadedmetadata = () => {
            setAudioLoaded(true);
            audioRef.current.play();
          };

          audioRef.current.onended = () => {
            setAudioLoaded(false);
          };
        }
      }
    };

    webSocket.current.onclose = () => {
      console.log("WebSocket Disconnected");
    };
  };

  const handleClickOkay = () => {
    setShowFirst(false);
    setShowSecond(true);
    connectWebSocket();
  };

  // Tooltip setup for the "Next Question" button
  const renderTooltipTopFirst = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="open-ai">
        Once AI has finished speaking the question, You will need to answer.
        <button className="btn btn-okay" onClick={handleClickOkay}>
          Ok
        </button>
      </div>
    </Tooltip>
  );

  const renderTooltipTop = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      AI is listening, please provide the answer and click 'Next question'
    </Tooltip>
  );

  const renderTooltipBottom = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If you’re unsure about the answer. Kindly click on ‘I don’t know the
      answer’ button
    </Tooltip>
  );

  const handleClickStartInterview = () => {
    navigate(`/meeting/${token}/start`);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "80vh" }}
    >
      <div style={{ flex: "1" }}>
        <Modal show={showFirst} centered size="lg">
          <OverlayTrigger
            placement="top"
            overlay={renderTooltipTopFirst}
            show={true}
            animation={false}
          >
            <Modal.Body
              className="d-flex justify-content-center align-items-center"
              style={{
                background: "#EAF5FE",
                borderRadius: "8px",
                padding: "70px 50px",
                position: "relative",
              }}
            >
              <div
                className="p-3"
                style={{
                  borderRadius: "6px",
                  width: "100%",
                  maxWidth: "600px",
                  border: "1px solid #d4d4d8",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fs-5 interview-heading-primary mb-0">
                    What is your name?
                  </p>
                  <span className="text-muted">1/10</span>
                </div>
              </div>
            </Modal.Body>
          </OverlayTrigger>
        </Modal>

        <Modal show={showSecond} centered size="lg">
          <OverlayTrigger
            placement="top"
            overlay={renderTooltipTop}
            show={showThired ? false : true}
            animation={false}
          >
            <Modal.Body
              className={`d-flex justify-content-center ${
                showThired ? "modal-disabled" : ""
              }`}
              style={{ background: "#EAF5FE" }}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltipBottom}
                show={showThired ? false : true}
                animation={false}
              >
                <div
                  className="card question-card w-100"
                  style={{ marginTop: "4%" }}
                >
                  <div className="card-body text-left">
                    <div className="card-container m-t-20">
                      <p className="fs-5 interview-heading-primary">
                        {currentQuestions
                          ? currentQuestions
                          : "What is your name?"}
                      </p>
                      <div className="d-flex justify-content-start align-items-center mb-3 border-top-bottom transcribing-answer" style={{padding: "0"}}>
                        <span className="wave-loader">
                          <Loader />
                        </span>
                        <span>Transcribing Answer</span>
                      </div>
                      <div className="d-flex flex-column flex-md-row justify-content-md-end">
                        <button className="btn btn-outline-interview mb-3 mb-md-0 me-md-3">
                          I don't know the answer
                        </button>
                        <button
                          className="btn btn-primary-interview d-flex align-items-center"
                          disabled={showThired}
                          onClick={() => {
                            setShowThired(true);
                          }}
                        >
                          <img
                            src={Icon}
                            alt=""
                            className="me-2"
                            style={{ width: "auto", height: "auto" }}
                          />
                          Next Question
                        </button>
                      </div>
                      <audio
                        ref={audioRef}
                        controls={false}
                        style={{
                          width: 0,
                          height: 0,
                          opacity: 0,
                          position: "absolute",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </OverlayTrigger>
            </Modal.Body>
          </OverlayTrigger>
        </Modal>

        <Modal
          show={showThired}
          centered
          backdrop="static"
          className="start-interview"
        >
          <Modal.Body className="d-flex justify-content-center">
            <div
              className="card question-card w-100"
              style={{ marginTop: "20px", boxShadow: "none" }}
            >
              <div
                className="card-body text-center"
                style={{ padding: "10px" }}
              >
                <h4 className="fs-5 interview-heading-primary">Great!</h4>
                <p className="interview-text-primary">
                  Let’s Begin your interview
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary-interview"
                    style={{ width: "350px", height: "55px" }}
                    onClick={handleClickStartInterview}
                  >
                    Start Interview
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {showFirst && (
        <div
          className="text-center"
          style={{ marginTop: "auto", paddingBottom: "20px" }}
        >
          <img src={wave} alt="" />
        </div>
      )}
    </div>
  );
};

export default InterviewSetup;
