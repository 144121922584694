import React from "react";
import Loader from "../../pages/public/Loader";
const PreparingQuestion = (props) => {
  const {isFirstTime} = props;
  return (
    <div className="d-flex justify-content-center align-items-center success-container">
      <div className="text-center">
        <Loader/>
        <h4 className="interview-heading-primary">
          {isFirstTime ? "Preparing Question..." : "Preparing next question..."}
        </h4>
      </div>
    </div>
  );
};

export default PreparingQuestion;
