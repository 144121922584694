import React from "react";
import { Route, Routes } from "react-router-dom";
import PreLoginLayout from "../../components/Layouts/PreLoginLayout";
import Login from "./Auth/Login";

const PublicPortal = () => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<>{<PreLoginLayout />}</>}>
          <Route
            path=""
            element={
              <React.Suspense fallback={<></>}>
                <Login />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default PublicPortal;
