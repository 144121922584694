import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Icon from "../../../assets/img/Icon.svg";
import aidaash from "../../../assets/img/ai-daash.png";
import audiowave from "../../../assets/img/audio_wave.svg";
import wave from "../../../assets/img/sound-wave-icon.svg";

const InterviewOverview = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const handleClickStartInterview = () => {
    navigate(`/meeting/${token}/setup`);
  };
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    setUserName(storedUserName);

    function setUsernameFromLocalStorage() {
      const storedUserName = localStorage.getItem("userName");
      setUserName(storedUserName);
    }
    setTimeout(setUsernameFromLocalStorage, 2000);
  }, [token]);

  return (
    <div className="container main-container">
      <div className="px-4 py-2 m-t-20">
        <h1 className="interview-heading overview-heading">
          {" "}
          Hey {!userName ? <span className="shimmer-loader"></span> : userName}!
          Welcome
        </h1>
        <img
          src={aidaash}
          alt=""
          className="aidaash"
          style={{ width: "auto", height: "auto" }}
        />

        <p className="interview-text-primary">
          Our AI-powered interview is designed to assess your Salesforce
          expertise through spoken responses. Follow the quick steps below to
          see how it works.
          <br />
          <br />
          When you’re ready to begin, click "Start Interview."
        </p>
        <hr className="my-5" />
      </div>
      <div className="how-it-work-container">
        <p className="how-it-work-heading">HOW IT WORKS</p>
        <div className="how-it-work">
          <div className="">
            <div className="stepper">
              <button className="stepper-button" style={{ marginTop: "30px" }}>
                1
              </button>
              <h4 className="interview-heading-primary">
                First the AI will ask the question.
              </h4>
              <p className="interview-text-primary">
                Listen carefully, as the question won't be repeated.
              </p>
              <div className="card">
                <div className="card-body text-center">
                  <div className="card-container m-t-20">
                    <p className="fs-5 interview-heading-primary">
                      Can you explain the main difference between a lookup
                      relationship and a master-detail relationship in
                      Salesforce?
                    </p>
                  </div>
                  <img src={wave} alt="" />
                </div>
              </div>
            </div>
            <div className="stepper mt-4">
              <button className="stepper-button " style={{ marginTop: "30px" }}>
                2
              </button>
              <h4 className="interview-heading-primary">
                Respond immediately after the AI finishes speaking.
              </h4>
              <p className="interview-text-primary">
                As soon as the AI completes the question, respond right away.
              </p>
              <div className="card">
                <div className="card-body text-center">
                  <div className="card-container">
                    <p className="fs-5 interview-heading-primary">
                      Can you explain the main difference between a lookup
                      relationship and a master-detail relationship in
                      Salesforce?
                    </p>
                    <div className="d-flex justify-content-start align-items-center mb-3 border-top-bottom transcribing-answer">
                      <img src={audiowave} alt="" className="me-2" />
                      <span>Transcribing Answer</span>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-md-end">
                      <button className="btn btn-outline-interview-default mb-3 mb-md-0 me-md-3">
                        I don't know the answer
                      </button>
                      <button className="btn btn-primary-interview-default d-flex align-items-center">
                        <img
                          src={Icon}
                          alt=""
                          className="me-2"
                          style={{ width: "auto", height: "auto" }}
                        />
                        Next Question
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="stepper ">
              <button className="stepper-button" style={{ marginTop: "55px" }}>
                3
              </button>
              <br />

              {/* <p className="my-3">
                   Can you explain the main difference between a lookup <br/> relationship and a master-detail relationship in Salesforce?
                </p> */}
              <h4 className="interview-heading-primary">
                If you're finished or don’t have an answer, choose one of the
                available options.
              </h4>
              <p className="interview-text-primary">
                When you've completed your answer, press ‘Next Question.’ If
                you're unsure, select the ‘I don’t know the answer’ button.{" "}
              </p>
              <div className="card">
                <div className="card-body text-center">
                  <div className="card-container">
                    <p className="fs-5 interview-heading-primary">
                      Can you explain the main difference between a lookup
                      relationship and a master-detail relationship in
                      Salesforce?
                    </p>
                    <div className="d-flex justify-content-start align-items-center mb-3 border-top-bottom transcribing-answer">
                      <img src={audiowave} alt="" className="me-2" />

                      <span>Transcribing Answer</span>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-md-end">
                      <button className="btn btn-outline-interview-default mb-3 mb-md-0 me-md-3">
                        I don't know the answer
                      </button>
                      <button className="btn btn-primary-interview-default d-flex align-items-center">
                        <img
                          src={Icon}
                          alt=""
                          className="me-2"
                          style={{ width: "auto", height: "auto" }}
                        />
                        Next Question
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            className="btn btn-lg btn-primary-interview"
            onClick={handleClickStartInterview}
          >
            Start Interview
          </button>
        </div>
      </div>
    </div>
  );
};

export default InterviewOverview;
