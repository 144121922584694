import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { api } from "../../../api/apiService";
import ConfirmDialog from "../../../components/share/ConfirmDialog";

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Username is required")
    .min(3, "Username must be at least 3 characters"),
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be valid"),
  password: yup.string().when("isEditMode", {
    is: false,
    then: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  }),
});

const Users = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [alert, setAlert] = useState(false);
  const [userId, setUserId] = useState();
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("users");
      setUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const openModal = () => {
    reset();
    setSelectedUser(null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const onSubmit = async (data) => {
    // setIsLoading(true);
    try {
      if (selectedUser) {
        await api.put(`users/${selectedUser.id}`, data);
        setUsers(
          users.map((user) =>
            user.id === selectedUser.id ? { ...user, ...data } : user
          )
        );
        toast.success("User updated successfully");
      } else {
        const response = await api.post("users", data);
        setUsers([response.data, ...users]);
        toast.success("User added successfully");
      }
      reset();
      closeModal();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  const handleEditUser = (user) => {
    setValue("username", user.username);
    setValue("email", user.email);
    setSelectedUser(user);
    setIsModalOpen(true);
  };
  const handleDeleteUser = (userId) => {
    setUserId(userId);
    setAlert(true);
  };

  const confirmDelete = async () => {
    setAlert(false);
    try {
      await api.delete(`users/${userId}`);
      setUsers(users.filter((user) => user.id !== userId));
      toast.success("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
      setAlert(false);
    } finally {
      setAlert(false);
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Users</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/user/meetings">Home</Link>
            </li>
            <li className="breadcrumb-item">Users</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="col-12">
                  <h5 className="card-title" />
                  <Button className="btn btn-primary" onClick={openModal}>
                    Add User
                  </Button>
                  <div className="container mt-4">
                    <div className="row">
                      <div className="col-md-8">
                        <h2>Users List</h2>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Username</th>
                              <th scope="col">Email</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <tr>
                                <td
                                  colSpan="4"
                                  className="text-center mt-4 fw-bold"
                                >
                                  <div className="spinner-border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>{" "}
                                </td>
                              </tr>
                            ) : (
                              users.length > 0 &&
                              users.map((user, index) => {
                                return (
                                  <tr key={user?.id}>
                                    <td>{index + 1}</td>
                                    <td>{user?.username}</td>
                                    <td>{user?.email}</td>
                                    <td>
                                      <span
                                        title="Edit"
                                        className="text-primary me-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleEditUser(user)}
                                      >
                                        <i className="bi bi-pencil"></i>
                                      </span>
                                      {user.admin !== 1 && (
                                        <span
                                          title="Delete"
                                          className="text-danger me-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleDeleteUser(user.id)
                                          }
                                        >
                                          <i className="bi bi-trash"></i>
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmDialog
        open={alert}
        setOpen={setAlert}
        dialogTitle="Are you sure?"
        handleConfirmYes={confirmDelete}
      />
      {/* React Bootstrap Modal */}
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUser ? "Edit User" : "Add New User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.username ? "is-invalid" : ""
                }`}
                id="username"
                placeholder="Enter username"
                {...register("username")}
              />
              {errors.username && (
                <div className="invalid-feedback">
                  {errors.username.message}
                </div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                id="email"
                placeholder="Enter email"
                {...register("email")}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email.message}</div>
              )}
            </div>

            {!selectedUser && (
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  id="password"
                  placeholder="Enter password"
                  {...register("password")}
                />
                {errors.password && (
                  <div className="invalid-feedback">
                    {errors.password.message}
                  </div>
                )}
              </div>
            )}

            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {selectedUser ? "Update User" : "Save changes"}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Users;
