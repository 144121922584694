import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import audiowave from "../../assets/img/audio_wave.svg";
import PreparingQuestion from "./PreparingQuestion";
import Icon from "../../assets/img/Icon.svg";
import Loader from "../../pages/public/Loader";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../api/apiService";

const ListingQuestion = forwardRef((props, ref) => {
  const { meetingId } = props;
  const { token } = useParams();
  const [isRecording, setIsRecording] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [currentQuestions, setCurrentQuestions] = useState("");
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);
  const navigate = useNavigate();
  const { listingQuestionRef } = useOutletContext();

  const webSocket = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaStream = useRef(null);
  const audioRef = useRef(null);
  const isDevMode = process.env.REACT_APP_MODE === "dev";
  const shouldSendData = useRef(true);
  const wsUrl = `${process.env.REACT_APP_WEB_SOCKET}/${meetingId}`;
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    
    const response = api.get(`meetings/${token}?status=completed`);

    const connectWebSocket = () => {
      webSocket.current = new WebSocket(wsUrl);

      webSocket.current.onopen = () => {
        console.log("WebSocket Connected");
        if (webSocket.current.readyState === WebSocket.OPEN) {
          webSocket.current.send("next_question");
        }
      };

      webSocket.current.onmessage = (event) => {
        if (typeof event.data === "string") {
          console.log("Interview Started:", event.data);
          setCurrentQuestions(event.data);
          setIsLoadingQuestion(false);
        } else {
          console.log("Audio message received:", event.data);
          setIsLoadingQuestion(false);
          handleClickNewQuestion(false);
          const audioBlob = new Blob([event.data], { type: "audio/wav" });
          const audioUrl = URL.createObjectURL(audioBlob);

          if (audioRef.current) {
            console.log("Setting audio source and event handlers");
            audioRef.current.src = audioUrl;
            audioRef.current.onloadedmetadata = () => {
              setAudioLoaded(true);
              audioRef.current.play();
            };

            audioRef.current.onended = () => {
              console.log("Audio playback ended, starting recording");
              setAudioLoaded(false);
              startRecording();
            };
          } else {
            console.error("audioRef.current is null");
          }
        }
      };

      webSocket.current.onclose = () => {
        stopInterview();
        console.log("WebSocket Disconnected");
      };
    };

    const timeoutId = setTimeout(connectWebSocket, 1000);

    return () => {
      if (webSocket.current) {
        webSocket.current.close();
      }
      clearTimeout(timeoutId);
    };
  }, [wsUrl]);

  // const handleClickNewQuestion = async () => {
  //   setIsLoadingQuestion(true);
  //   if (webSocket.current && webSocket.current.readyState === WebSocket.OPEN) {
  //     webSocket.current.send("next_question");
  //   }
  // };

  const handleClickIdontKnow = async () => {
    setIsLoadingQuestion(true);
    if (webSocket.current && webSocket.current.readyState === WebSocket.OPEN) {
      webSocket.current.send("i_dont_know");
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.src = "";
      }
    }
  };

  const stopInterview = async () => {
    if (audioLoaded) {
      await handleClickNewQuestion(false);
    } else  {
      await handleClickNewQuestion(true);
    }

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.src = "";
    }

    if (webSocket.current) {
      webSocket.current.close();
      navigate(`/meeting/${token}/all-done`);
      
    }
  };

  const startRecording = async () => {
    try {
      mediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const mimeType = "audio/webm";
      if (!MediaRecorder.isTypeSupported(mimeType)) return;

      const recorder = new MediaRecorder(mediaStream.current, { mimeType });
      mediaRecorder.current = recorder;

      recorder.ondataavailable = (e) => {
        if (
          shouldSendData.current &&
          webSocket.current &&
          webSocket.current.readyState === WebSocket.OPEN &&
          e.data.size > 0
        ) {
          console.log("sending message from 125");
          webSocket.current.send(e.data);
        }
      };

      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing the microphone:", error);
    }
  };

  const handleClickNewQuestion = (sendData = true) => {
    return new Promise((resolve) => {
      setIsLoadingQuestion(true);
      shouldSendData.current = sendData;
      if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
        mediaRecorder.current.onstop = () => {
          if (mediaStream.current) {
            mediaStream.current.getTracks().forEach((track) => track.stop());
          }
          setIsRecording(false);
          mediaRecorder.current = null;
          resolve();
        };
        mediaRecorder.current.stop();
      } else {
        if (mediaStream.current) {
          mediaStream.current.getTracks().forEach((track) => track.stop());
        }
        setIsRecording(false);
        resolve();
      }
    });
  };

  useImperativeHandle(listingQuestionRef, () => ({
    stopRecordingFromParent: async () => {
      console.log("clicked");
      await stopInterview();
    },
  }));

  return (
    <>
      <div>
        {!isLoadingQuestion ? (
          <>
            {!currentQuestions ? (
              <PreparingQuestion isFirstTime={true} />
            ) : (
              // Main content including the question card
              <div className="card question-card">
                <div className="card-body text-left">
                  <div className="card-container m-t-20">
                    <p className="fs-5 interview-heading-primary">
                      {currentQuestions}
                    </p>
                    <div
                      className="d-flex justify-content-start align-items-center mb-3 border-top-bottom transcribing-answer"
                      style={{ padding: "0" }}
                    >
                      <span className="wave-loader">
                        <Loader />
                      </span>
                      <span>Transcribing Answer</span>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-md-end">
                      <button
                        className="btn btn-outline-interview mb-3 mb-md-0 me-md-3"
                        onClick={handleClickIdontKnow}
                      >
                        I don't know the answer
                      </button>
                      <button
                        className="btn btn-primary-interview d-flex align-items-center"
                        onClick={() => handleClickNewQuestion(true)}
                        disabled={audioLoaded}
                      >
                        <img
                          src={Icon}
                          alt="Next Question Icon"
                          className="me-2"
                          style={{ width: "auto", height: "auto" }}
                        />
                        Next Question
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <PreparingQuestion isFirstTime={false} />
        )}
        <audio
          ref={audioRef}
          controls={false}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
            position: "absolute",
          }}
        />
      </div>
    </>
  );
});

export default ListingQuestion;
