import React from "react";
import { Route, Routes } from "react-router-dom";
import PostLoginLayout from "../../components/Layouts/PostLoginLayout";
import Users from "./Users/Users";
import Profile from "./Profile/Profile";
const Meetings = React.lazy(() => import("./Meetings/Meetings"));

const PrivatePortal = () => {
  return (
    <React.Suspense fallback={<></>}>
      <Routes>
        <Route path="/" element={<PostLoginLayout />}>
          <Route
            path="/meetings"
            element={
              <React.Suspense fallback={<></>}>
                <Meetings />
              </React.Suspense>
            }
          />
          <Route
            path="/users"
            element={
              <React.Suspense fallback={<></>}>
                <Users />
              </React.Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <React.Suspense fallback={<></>}>
                <Profile />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default PrivatePortal;
