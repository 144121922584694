import React from "react";
import ListingQuestion from "../../../components/AIInterview/ListingQuestion";
const InterviewStart = () => {
  const meetingId = localStorage.getItem("id");

  return (
    <div className="container w-75 m-auto">
      <ListingQuestion
        meetingId={meetingId}
      />
  </div>
  );
};

export default InterviewStart;
