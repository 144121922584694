import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../../api/apiService";
import logo from "../../assets/img/logo.png";
import "../../assets/interview.css";
import "../../assets/shimmerEffect.css";


const InterviewLayout = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const location = useLocation();
  console.log("Token from URL:", token);
  const listingQuestionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMeeting = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`meetings/${token}?status=pending`);

      localStorage.setItem("userName", response.data.user_name);
      localStorage.setItem("id", response.data.id);
      setIsLoading(false);
      if (response.data.status === "completed") {
        navigate(`meeting-expired`);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      if (error.data && error.data.detail) {
        navigate(`meeting-expired`);
      }
      console.error("Failed to fetch meeting:", error.data.detail);
    }
  };

  useEffect(() => {
    if (token) {
      fetchMeeting();
    }
  }, [token]);

  useEffect(() => {
    const handlePopState = () => {
      if (token) {
        fetchMeeting();
      }
    };

    // Listen for popstate event
    window.addEventListener('popstate', handlePopState);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [token]);

  const handleStopeInterview = async () => {
    try {
      if (listingQuestionRef.current) {
        await listingQuestionRef.current.stopRecordingFromParent();
        await fetchMeeting();
      }

      // After stopping the recording, navigate to the all-done page
      // navigate(`/meeting/${token}/all-done`);
    } catch (error) {
      console.error("Failed to stop interview:", error);
    }
  };

  const isInterviewPage = location.pathname.includes("start");

  return (
    <div className="container-fluid px-0">
      <nav className="navbar bg-white">
        <div className="container d-flex justify-content-center align-items-center">
          <a className="navbar-brand" href="#">
            <img src={logo} alt="Logo" style={{ maxHeight: "70px" }} />
          </a>
          {isInterviewPage && (
            <button
              className="btn btn-danger position-absolute end-0 me-5"
              onClick={handleStopeInterview}
              disabled={isLoading}
            >
              Stop Interview
            </button>
          )}
        </div>
      </nav>
      <Outlet context={{ listingQuestionRef }} />
    </div>
  );
};

export default InterviewLayout;
